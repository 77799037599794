import React from "react"
import Container from './container'

const ThanksMessage = () => (
  <Container>
  <p>Vi kontaktar dig snarast möjligt.</p>
  </Container>
)

export default ThanksMessage
