import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ThanksMessage from '../components/thanks'

class FormSubmittedPage extends React.Component {
  render() {

    return (
      <Layout location={this.props.location}>
        <Seo title="Tack" />
        <Hero title="Tack!" />
        <ThanksMessage />
      </Layout>
    )
  }
}

export default FormSubmittedPage
